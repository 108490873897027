import { SequelizeOperator } from "@/pages/Inbox/protocols/InstanceProtocol"

export const sequelizeOperatorValues: SequelizeOperator[] = [
	SequelizeOperator.Between,
	SequelizeOperator.BeforeOrEqual,
	SequelizeOperator.AfterOrEqual
]

export const sequelizeOperatorToString: Record<SequelizeOperator, string> = {
	[SequelizeOperator.Between]: "Entre",
	[SequelizeOperator.BeforeOrEqual]: "Anterior",
	[SequelizeOperator.AfterOrEqual]: "Após"
}

export const sequelizeOperatorToDateRange: Record<SequelizeOperator, { field: string; label: string }[]> = {
	[SequelizeOperator.Between]: [
		{ field: "before", label: sequelizeOperatorToString[SequelizeOperator.BeforeOrEqual] },
		{ field: "after", label: sequelizeOperatorToString[SequelizeOperator.AfterOrEqual] }
	],
	[SequelizeOperator.BeforeOrEqual]: [
		{ field: "before", label: sequelizeOperatorToString[SequelizeOperator.BeforeOrEqual] }
	],
	[SequelizeOperator.AfterOrEqual]: [
		{ field: "after", label: sequelizeOperatorToString[SequelizeOperator.AfterOrEqual] }
	]
}
